import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../bigbuy-group-library/Button';

import 'app/components/Promo/Promo.scss';

export default function Promo({ multiple, image, buttons }) {
  return (
    <div className="bb-promo-card">
      <img src={image} alt={image} />
      <div
        className={classNames('bb-promo-card__links', {
          'bb-promo-card__links--multiple': multiple
        })}
      >
        {buttons.map((x) => (
          <Button
            key={x.content}
            variant={x.variant}
            type={x.type}
            onClick={() => window.open(x.url)}
            size="small"
          >
            {x.content}
          </Button>
        ))}
      </div>
    </div>
  );
}

Promo.defaultProps = {
  multiple: false
};

Promo.propTypes = {
  multiple: PropTypes.bool,
  image: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
      variant: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};
